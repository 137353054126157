import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Brak strony</h1>
    <p>Strona nie istnieje.</p>
  </Layout>
)

export default NotFoundPage
